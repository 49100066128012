footer{
  background-color: black;

  height: auto;
}
.designImg {
}
.dsignpic {
  width: 90px;
  display:inline-block;



}
.sDesigns-div {
  display: flex;
  justify-content: center;
  border-top: solid;
  border-top-color: white;
  border-top-width: thin;
}
.website > h5 {
  display: inline-block;
  color: white;
  font-size: 10px;
  letter-spacing: 2px;
  font-weight: 500;
  margin-top: 39px;
  margin-right: 12px;
}
.icondiv {
  display: flex;
  justify-content: space-around;
  border-top: solid;
  border-color: white;
  border-width: thin;
}
.fbookdiv {

}
.prest {
  font-size: 55px;
  color: pink;
}
.prest:hover {
  color: pink;
}
.fbook {
  font-size: 55px;

  color: #00FFEF;
}
.fbook:hover {
  color:#00FFEF;
}
.address {
  color: white;
  text-align: center;
}
.salon {

  color: white;
}
.top-div {
  display: flex;
  justify-content: space-between;
  border-top: solid white thin;
}
.rsdiv {
  border-left: solid white thin;
  display: flex;
  justify-content: center;
}
.rightside {
  border-left: solid white thin;
  width: 50%;
}
.emailDiv {
  text-align:center;
}
.emailDiv > a {
  color: pink;
}
.contactDiv {
  text-align:center;
  color: white;
  letter-spacing: 10px;
  font-size: 12px;
}
.numberDiv {
  text-align:center;
}
.numberDiv > a {
  color: pink;
}
.locationDiv {
  border-top: solid white thin;
  margin-top: 4%;
  padding-top: 3%;
  text-align:center;
  color: white;
  letter-spacing: 10px;
  font-size: 12px;

}
.salon {
  text-align:center;
  margin-bottom: -2%;
}
.adddiv {
  text-align:center;
}
.adddiv > a {
  color:pink
}
.inner-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ptag {
  color: white;
  font-size: 12px;
  letter-spacing: 3px;
  text-align: center;
}
.hoursDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;

  border-top: solid white thin;
}
.hour {
  margin-top: 2%;
}
