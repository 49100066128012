.topContact {
  width: 100%;
  text-align: center;
  height: auto;
}
.topContact > img {
  width: 50%;
  height: auto;
}
.formDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;
  margin-bottom: 10%;
  margin-top: 10%;
}
form {

  display: flex;
  flex-direction: column;

  width: 45%;
}
input {
  border: solid #FF86CD thin;
  text-align: center;
  margin-top: 5%;
  font-weight: 100;
  letter-spacing: 2px;
}
input:focus {
    outline: none;
}
.submit {
  margin-left: 34%;
  margin-top:4%;
  width: 30%;
  text-align: center;
  margin-bottom: 5%;
  background-color: white;
  border:none;
  font-weight: 500;
  text-decoration: underline overline line-through;
}
.message {
  padding-bottom: 60px;
}
.contactInfo {
  width: 100%;
  height: auto;
  background-color: #FFE4DF;
  text-align: center;
  margin-bottom: 2%;
}
.emailContact {
  padding-top: 1%;
  color: #89736F;
}
.emailContact:hover {
  color: #89736F;
}
.phone {
  color: #89736F;
}
.phone:hover {
  color: #89736F;
}
.locationContact > p {
  color:#89736F;
  margin-top: 1.5%;
}
.salonContact > p {
  color:#89736F;
  margin-top:1%;
}
.addContact {
  color:#89736F;
  padding-bottom: 3%;
  margin-top: -1%;
}
.addContact:hover {
  color: #89736F;
}
/* .bottomContactpic {
  width: 100%;
  height: 20rem;
  background-image: url('./../../images/bek1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
} */
