a.nav-link {
  color: black!important;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 2px;

  margin-left: 4%;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg viewBox='0 10 0' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(0,0,0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

a.nav-link.nav-about {
  margin-left: 3rem;
  font-size: 9px;
  margin-top: 8px;
  letter-spacing: 8px;
  transition-duration: 1s;
}
a.nav-link.nav-about:hover {
  /* transition-duration: 1s;
  color:#00FFEF!important;
  font-weight: bold; */
  text-decoration:  line-through;
}
a.nav-link.nav-services {
  margin-left: 3rem;
  margin-top: 8px;
  letter-spacing: 8px;
  font-size: 9px;
  transition-duration: 1s;
}
a.nav-link.nav-services:hover {
  text-decoration:  line-through;
}
a.nav-link.nav-contact {
  margin-left: 3rem;
  letter-spacing: 8px;
  margin-top: 8px;
  font-size: 9px;
  transition-duration: 1s;
}
a.nav-link.nav-contact:hover {
  text-decoration:  line-through;
}



.tools {
  margin-top: 30px;
  margin-right: 4rem;
}
