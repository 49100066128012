.top-intro-div {
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height:auto;
  /* width: 100%; */
}
.bekblkwhthome {
  margin: 0 auto;
  width: 100%;

}
.bek-photo-div {
  margin: 0 auto;
  width: 90.5%;
  display:flex;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: center;

}

.intro-right {
  margin: 0 auto;
  font-size: 40px;


}
.fa-star {
  color: #E6E836;
  margin: 10px;
  padding-top: 40%;

}
/* .ratebox {
  display:flex;
  flex-direction: column;
  margin:0 auto;

} */
.rating {
  text-align: center;
  margin-top: 40%;
}
.americancrew {
  width: 35%;
}
.aquage {
   width: 35%;
}
.bedhead {
  width: 35%;
}
.rowOne {

  margin-left: 10%;
  display: flex;
  justify-content: space-around;
}
.goldwell {
  width: 35%;
}
.rowThree {
  margin-top: 3%;
  margin-left: 8%;
  display: flex;
  justify-content: space-around;
}
.itsaten {
  width: 30%;
}
.joicologo {
  width: 45%;
}
.rowTwo {
  margin-top: 3%;
  margin-left: 10%;
  display: flex;
  justify-content: space-around;
}
/* .rating > h3 {
  font-family:sans-serif;
  font-weight: 500;
  font-size: 25px;
  letter-spacing: 3px;
} */
.home-card-div {
  width: 60%;
  height: auto;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-left: 8%;
  transform: rotate(350deg);
  margin-top: 5%;
  transition-duration: 1s;
}
/* .home-card-div:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 255, 239), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition-duration: 1s;
} */
.mobile-bekblkwhthome {
  width: 100%;
}
.products {
  height:auto;
  margin-top: 7%;
  margin-bottom: 7%;
}
.matrix {
  width: 35%;

}
.kms {
  width: 45%;

}
.rowFour {
  margin-top: 5%;
  margin-left: 8%;
  display: flex;
  justify-content: space-around;
}

.rowFive {
  margin-top: 5%;
  margin-left: 10%;
  display: flex;
  justify-content: space-around;
}
.moroccanoil {
  width: 35%;
}
.sexyhairlogo {
  width: 45%;
}
.testimonial {
  height: auto;
  margin-top: 15%;
}
.missionBox {
  width: 100%;
  height: auto;
  /* background-image: url('./../../images/missionpic.jpeg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
  background-color: #FFE4DF;

  margin-top: 1%;

}

.missionContent {
  width: 100%;
  text-align: center;
  height:100%;
}
.missionContent > h1{
  color:;
  font-weight: 200;
  letter-spacing: 3px;
  margin-top: 5%;
  font-weight: 100;
}
.missionContent > p {
  font-size: 23px;
  width: 70%;
  margin-left: 15%;
  margin-top: 5%;
  line-height: 60px;
  margin-bottom: 8%;
  /* color:#969696; */
  letter-spacing: 6px;
  font-weight: 100;
}
.yearsDiv {
  position: relative;
  margin-left: 80%;
  bottom: 20rem;
}
.yearsDivPhone {
  position: relative;
  margin-left: 45%;
  bottom: 13rem;
}
.testp {
  text-align: center;
  font-size: 16px;
  line-height: 38px;
  margin-bottom: 3%;
  width: 90%;
  margin-left: 5%;
  font-weight: 200;
  margin-top: 5%;
}
.testi {
 text-align: center;
 font-weight: 600;
 font-size: 12px;
 letter-spacing: 3px;

}
/* .i {
  color: #FF86CD;
  font-weight: 400;
} */
.testline {
  border-bottom: solid #FF86CD thin;
  width:40%;
  margin-left: 30%;
  margin-bottom: 5%;
  margin-top: 5%;

}
.testm {
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 3px;
  margin-bottom: 3%;
}

.fa-star {
  font-size: 20px;
}
.mobile-testp {
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 2%;
  color: black;
}
.mobile-testimonial {
  margin-top: 10%;


}
