.toppic > img {
  width: 60%;

  margin-left: 22%;
}
.toppic {
  width:  100%;
}
.cuts {
  text-align: center;
  margin-top: 5%;
  height: auto;
}
.cuts > h2 {

  margin-top: 1%;
  font-size: 22px;
  letter-spacing: 1px;
  font-family:  sans-serif;
  font-weight: 300;


}
.cuts > h4 {
  color:#999999;
  margin-top: 3%;
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 300;
}
.cuts > img {
  width: 6%;
}
.a {
  color: #FF86CD;
}
.bottomP {
  margin-bottom: 5%;
}

.inq {
  width: 100px;
}
